module.exports = {
  customer: 'lewisham',
  environment: 'production',
  yourMoney: true,
  jobsVolunteering: true,
  googleAnalyticsId: 'G-8X68TFW2QD',
  robots: true,
  chatWidget: false,
  entitledToBaseUrl: 'https://lewishambetteroff.entitledto.co.uk',
  entitledToReassessUrl: 'https://lewishambetteroff.entitledto.co.uk/reassessbetteroff',
  requirePostcode: false,
  fundedByUKGov: true,

  app: {
    home: 'https://lewisham.betteroff.org.uk'
  },
  serverBaseUrl: 'https://api-lewisham.betteroff.org.uk',
  api: {
    baseUrl: 'https://api-lewisham.betteroff.org.uk/internal/v1'
  },
  authGateway: 'https://account.lewisham.betteroff.org.uk',
  loginServices: [
    {name: 'kerp'},
    {name: 'phone'},
    {name: 'google', postLogoutUrl: 'https://accounts.google.com/Logout'},
    {name: 'facebook'},
    {name: 'agent'},
    {name: 'referenceNumber'}
  ],
  ui: {
    showJobSearchJournal: true,
    showOfflineForms: true,
    showAssessmentMutators: false,
    restrictions: {
      applications: false
    }
  },
  airbrake: {
    enabled: true,
    projectId: 131082,
    projectKey: 'facc9a060872b357453b677f15d319e3'
  }
};
